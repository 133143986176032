//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppCloseIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M16.59 6L12 10.59L7.41003 6L6 7.41003L10.59 12L6 16.59L7.41003 18L12 13.41L16.59 18L18 16.59L13.41 12L18 7.41003L16.59 6Z' style={props.secondaryStyle} />
        </g>
    ),
    'AppCloseIcon', // aria-label
);
