import {Palette, Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {ToiAvatarProps} from './ToiAvatar';

export type ToiAvatarColors = 'grey' | 'green' | 'red' | 'blue' | 'orange' | 'seaGreen' | 'purple';

const getColorsFromPalette = (palette: Palette) => [
  palette.black[70],
  ...[palette.green, palette.red, palette.orange, palette.blue, palette.seaGreen, palette.purple].map(
    (color) => color[100],
  ),
];

const getColorCode = (str: string) => {
  // djb2 hash
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i);
  }
  return hash >>> 16;
};

const colorIndex = (charCode: number, min: number, max: number) => {
  const r = max - min;
  return min + ((((charCode - min) % r) + r) % r);
};

// Assign color based on name or initials
const getColorForString = (colors: string[], initials?: string, fullName?: string) => {
  const str = fullName ? fullName : initials;
  return str ? {backgroundColor: colors[colorIndex(getColorCode(str), 0, colors.length)]} : {};
};

export function toiAvatarStyle({theme, size, initials, fullname, colorOverride}: ToiAvatarProps & {theme: Theme}) {
  const palette = theme.palette;
  const defaultStyle = {color: palette.white};
  const colors = getColorsFromPalette(palette);

  const sizeStyle = (size?: string) => {
    if (size === 'small') {
      return {width: '28px', height: '28px', fontSize: '14px', fontWeight: '700'};
    }
    if (size === 'medium') {
      return {width: '36px', height: '36px', fontSize: '16px', fontWeight: '600'};
    }
    if (size === 'large') {
      return {width: '48px', height: '48px', fontSize: '20px', fontWeight: '500'};
    }
    if (size === 'xl') {
      return {width: '64px', height: '64px', fontSize: '24px', fontWeight: '500'};
    }
    if (size === 'xxl') {
      return {width: '96px', height: '96px', fontSize: '36px', fontWeight: '700'};
    }
    return {width: '48px', height: '48px', fontSize: '20px', fontWeight: '500'};
  };

  // Overrides colors set from initials
  const getColorOverride = (colorOverride?: ToiAvatarColors) => {
    if (!colorOverride) return {};
    if (colorOverride === 'grey') {
      return {backgroundColor: palette.black[70]};
    }
    return {backgroundColor: palette[colorOverride][100]};
  };

  return merge(
    {},
    defaultStyle,
    sizeStyle(size),
    getColorForString(colors, initials, fullname),
    getColorOverride(colorOverride),
  );
}
