import {forwardRef} from 'react';
import {ToiIcon, ToiIconProps} from '../ToiIcon';

export const ArrowRightIcon = forwardRef<SVGSVGElement, ToiIconProps>((props, ref) => (
  <ToiIcon {...props} ref={ref}>
    <title>{props.title || 'Arrow Right'}</title>
    <desc>{props.desc || 'Arrow Right'}</desc>
    <path
      d='M14 6L20 12M20 12L14 18M20 12H4'
      stroke='var(--toi-palette-icons-secondary, #2E2D30)'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </ToiIcon>
));

export default ArrowRightIcon;
