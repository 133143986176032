import {Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import {
    ToiTypography,
    ToiStack,
    ToiLink,
    ToiList,
} from '@norkart/toi-components';
import {ToiThemeProvider} from '@norkart/toi-theme';
import TrackingListItem from './components/TrackingListItem';

export default function PersonvernPage() {
    return (
        <ToiThemeProvider>
            <ToiStack
                component={'article'}
                spacing={4}
                p={4}
                sx={{maxWidth: '1000px'}}
            >
                <header>
                    <ToiTypography variant='h1' sx={{maxWidth: '1000px'}}>
                        Personvernerklæring arealplaner.no
                    </ToiTypography>
                    <ToiTypography>
                        Norkart er databehandler og det er de enkelte kommunene
                        som er behandlingsansvarlig for personopplysningene som
                        er beskrevet i denne personvernerklæringen. Vi forklarer
                        her hva slags personopplysninger vi lagrer på vegne av
                        kommunen og hvordan de behandles. Denne
                        personvernerklæringen gjelder for arealplaner.no og de
                        data som er registrert i kommunens planregister.
                    </ToiTypography>
                </header>
                <section>
                    <ToiTypography variant='h4'>Vår postadresse:</ToiTypography>
                    <ToiTypography>
                        Norkart AS <br />
                        Hoffsveien 4 <br />
                        0275 Oslo
                    </ToiTypography>
                    <ToiTypography mt={2}>
                        Telefonnummeret vårt er 67 55 14 00.
                    </ToiTypography>
                    <ToiStack direction='row' spacing={1}>
                        <ToiTypography>
                            Du kan også ta kontakt med oss på:
                        </ToiTypography>
                        <ToiLink href='mailto:personvern@norkart.no'>
                            personvern@norkart.no
                        </ToiLink>
                    </ToiStack>
                </section>
                <section>
                    <ToiTypography variant='h2'>
                        Hvilke opplysninger vi har om deg
                    </ToiTypography>
                    <ToiTypography>
                        Når du besøker arealplaner.no, samler vi typisk inn
                        følgende informasjon om deg:
                    </ToiTypography>
                    <ToiList>
                        <TrackingListItem>
                            Din posisjon. Enten gjennom din IPadresse eller
                            posisjonsdata. I de fleste nettlesere vil du på
                            forhånd bli gjort oppmerksom på at du deler din
                            posisjon.
                        </TrackingListItem>
                        <TrackingListItem>IP adresse</TrackingListItem>
                        <TrackingListItem>Operativsystem</TrackingListItem>
                        <TrackingListItem>
                            Nettleserinformasjon
                        </TrackingListItem>
                        <TrackingListItem>Skjermoppløsning</TrackingListItem>
                        <TrackingListItem>Type enhet</TrackingListItem>
                        <TrackingListItem>
                            Navigasjon på nettsidene
                        </TrackingListItem>
                    </ToiList>
                    <ToiTypography>
                        Vi lagrer aldri informasjon som kan identifisere deg
                        personlig.
                    </ToiTypography>
                    <ToiTypography>
                        Disse opplysningene blir slettet etter 90 dager.
                    </ToiTypography>
                </section>
                <section>
                    <ToiTypography variant='h2'>
                        Våre underleverandører for databehandling
                    </ToiTypography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell variant='head'>Navn</TableCell>
                                <TableCell variant='head'>Formål</TableCell>
                                <TableCell variant='head'>
                                    Datalagringsregion
                                </TableCell>
                                <TableCell variant='head'>Kontakt</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Microsoft</TableCell>
                                <TableCell>
                                    Skytjenester for å levere tjeneste og
                                    autentisering
                                </TableCell>
                                <TableCell>EU</TableCell>
                                <TableCell>
                                    Microsoft Ireland Operations Limited <br />
                                    <br />
                                    One Microsoft Place,
                                    <br />
                                    South County Business Park,
                                    <br />
                                    Leopardstown, Dublin <br />
                                    18 D18 P521
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Cookie information</TableCell>
                                <TableCell>
                                    Informasjonskapsler og administrasjon av
                                    samtykke informasjonskapsler
                                </TableCell>
                                <TableCell>EU</TableCell>
                                <TableCell>
                                    Købmagergade 19
                                    <br />
                                    1150 Copenhagen, Denmark
                                    <br />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </section>
                <section>
                    <ToiTypography variant='h2'>
                        Rett til innsyn i egne opplysninger
                    </ToiTypography>
                    <ToiTypography>
                        Du kan be kommunen om kopi av opplysningene de har om
                        deg.
                    </ToiTypography>
                </section>
                <section>
                    <ToiTypography variant='h2'>Dine rettigheter</ToiTypography>
                    <ToiTypography>
                        Dersom du ønsker å begrense, protestere eller klage på
                        behandlingen av dine personopplysninger, ta kontakt med
                        den aktuelle kommunen.
                    </ToiTypography>
                </section>
            </ToiStack>
        </ToiThemeProvider>
    );
}
