//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const StatusInvisibleIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M4 4L19.5859 19.5859L18.1719 21L16.1937 19.0219C14.8817 19.6353 13.4693 20 12 20C7.15936 20 2.89734 16.1278 1 12C1.78442 10.2933 2.97467 8.63257 4.44196 7.27002L2.58594 5.41406L4 4ZM3.24164 12C4.92798 15.0287 8.19928 18 12 18C12.9279 18 13.8185 17.8079 14.6677 17.4958L13.8186 16.6467L10.5847 13.4128L7.35333 10.1814L5.86469 8.69275C4.79846 9.67847 3.89417 10.8281 3.24164 12ZM9.32123 6.49316C10.1724 6.17993 11.07 6 12 6C15.8007 6 19.072 8.97131 20.7584 12C20.1058 13.1721 19.2137 14.3334 18.1475 15.3193L19.5562 16.728C21.0226 15.3645 22.2156 13.7065 23 12C21.1027 7.87219 16.8406 4 12 4C10.5302 4 9.11517 4.36108 7.802 4.97388L9.32123 6.49316Z' style={props.secondaryStyle} />
            <path fillRule='evenodd' clipRule='evenodd' d='M17 12C17 12.6433 16.868 13.2537 16.6467 13.8186L10.1815 7.35327C10.7464 7.13196 11.3566 7 12 7C14.7614 7 17 9.23853 17 12ZM7 12C7 11.3566 7.13196 10.7463 7.35333 10.1814L13.8186 16.6467C13.2537 16.868 12.6434 17 12 17C9.23859 17 7 14.7615 7 12Z' style={props.primaryStyle} />
        </g>
    ),
    'StatusInvisibleIcon', // aria-label
);
