import {CssVarsThemeOptions, PaletteOptions} from '@mui/material/styles';
import {
  paletteColorsDark,
  paletteColorsLight,
  SemanticColors,
  semanticColorsDark,
  semanticColorsLight,
  toiColors,
  type ToiColors,
} from '@norkart/toi-colors';
import {type ToiColors as OldToiColors} from './oldToiColors';
import {toiPalette as oldToiPalette} from './oldToiPalette';

type ToiColorScale = keyof ToiColors[keyof ToiColors] | keyof OldToiColors[keyof OldToiColors];

type ToiColor = Record<ToiColorScale, string>;

export type ToiPalette = SemanticColors & ToiColors;

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

declare module '@mui/material/styles' {
  interface PaletteColor extends ToiColor {}

  interface Color extends ToiColor {}

  interface Palette extends ToiColors {
    error: PaletteColor;
    warning: PaletteColor;
    info: PaletteColor;
    success: PaletteColor;
    neutral: PaletteColor;
    red: Color;
    green: Color;
  }

  interface ColorSystemOptions {
    toi: {
      palette: DeepPartial<ToiPalette>;
    };
  }
}

const toiPalette: PaletteOptions = {
  ...oldToiPalette,
  ...toiColors,
};

export const toiColorSchemes: CssVarsThemeOptions['colorSchemes'] = {
  light: {
    palette: {
      ...toiPalette,
      ...paletteColorsLight,
    },
    toi: {
      palette: {
        ...toiColors,
        ...semanticColorsLight,
      },
    },
  },
  dark: {
    palette: {
      ...toiPalette,
      ...paletteColorsDark,
    },
    toi: {
      palette: {
        ...toiColors,
        ...semanticColorsDark,
      },
    },
  },
};
