export const toiBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,

    mobile: 0,
    mobilePortrait: 600,
    mobileLandscape: 600,
    tablet: 600,
    laptop: 900,
    desktop: 1200,
    ultrawide: 1536,
  },
};
