import {Theme} from '@mui/material';
import {merge} from 'lodash-es';

export const toiDialogTitleStyle = (theme: Theme) => {
  const defaultStyle = {
    paddingRight: '32px',
    paddingLeft: '32px',
    paddingTop: '24px',
    paddingBottom: '24px',
  };

  return merge({}, defaultStyle);
};
