import {createTheme, ThemeOptions} from '@mui/material/styles';
import {deepmerge} from '@mui/utils';

// eslint-disable-next-line no-restricted-imports
import {createBreakpoints} from '@mui/system';
import {toiPalette} from './toiPalette';
import './fonts';

export const DEFAULT_TYPOGRAPHY_VARIANT = 'body2' as const;
export type DefaultTypographyVariantOption = 'body1' | 'body2' | 'body3';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    mobile: true;
    mobilePortrait: true;
    mobileLandscape: true;
    tablet: true;
    laptop: true;
    desktop: true;
    ultrawide: true;
  }
}

export const toiTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Figtree',
  },
  palette: toiPalette,
  shape: {
    borderRadius: 0,
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,

      mobile: 0,
      mobilePortrait: 480,
      mobileLandscape: 768,
      tablet: 1024,
      laptop: 1200,
      desktop: 1920,
      ultrawide: 2560,
    },
  }),
};

export const createToiTheme = (overrides?: ThemeOptions) => {
  return createTheme(overrides ? deepmerge(toiTheme, overrides) : toiTheme);
};
