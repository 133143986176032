import {useAccount} from '@azure/msal-react';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {useIsLoggedIn} from '@norkart/nk-auth';
import {
    ToiAppBar,
    ToiAppBarLogin,
    ToiBox,
    ToiMenu,
    ToiMenuItem,
    ToiTypography,
} from '@norkart/toi-deprecated-components';
import {ToiThemeProvider} from '@norkart/toi-deprecated-theme';
import {useAuth} from '../../utils/auth';
import TopbarActions from './components/TopbarActions';
import TopbarKommuneHeader from './components/TopbarKommuneHeader';

export const Topbar = () => {
    const auth = useAuth();
    const account = useAccount();
    const isLoggedIn = useIsLoggedIn();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleHome = () => {
        navigate('/');
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isLoggedIn) setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        auth.logout();
        handleClose();
    };

    return (
        <ToiThemeProvider>
            <ToiAppBar
                title='Arealplaner'
                sx={(theme) => ({zIndex: theme.zIndex.drawer + 1})}
            >
                <ToiTypography
                    color='textPrimary'
                    component='a'
                    variant='h3'
                    onClick={handleHome}
                    sx={{cursor: 'pointer'}}
                >
                    Arealplaner
                </ToiTypography>
                <ToiBox sx={{pl: '30px'}} />
                <TopbarKommuneHeader />
                <ToiBox sx={{flexGrow: 1}} />
                <TopbarActions />
                <ToiBox sx={{pl: '30px'}} />
                <ToiAppBarLogin
                    isLoggedIn={isLoggedIn}
                    name={account?.name}
                    login={auth.login}
                    onClick={handleClick}
                />
            </ToiAppBar>
            <ToiMenu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableScrollLock
            >
                <ToiMenuItem onClick={handleLogout} key={'logout'}>
                    Logg ut
                </ToiMenuItem>
            </ToiMenu>
        </ToiThemeProvider>
    );
};

export default Topbar;
