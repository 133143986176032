/* istanbul ignore file */

import type {CancelablePromise} from '../../../norkartapicore';
import {Config, request as __request} from '../../../norkartapicore';
import type {MappedPlanAnalyseResponse} from '../models/MappedPlanAnalyseResponse';
import type {PlanerByGeometryRequest} from '../models/PlanerByGeometryRequest';
import type {PlanerRequest} from '../models/PlanerRequest';

export class PlanerService {
    config: Config;

    constructor(config: Config) {
        this.config = {BASE: 'https://plananalyse.api.norkart.no', ...config};
    }

    /**
     * @returns MappedPlanAnalyseResponse
     * @throws ApiError
     */
    public planerGetPlaner({
        knr,
        gnr,
        bnr,
        fnr,
        snr,
        teigid,
        includeSeksjoner,
        includeGeometries,
        includeGiplanData,
        includeArealUtenPlan,
        buffer,
        lang,
    }: {
        knr?: string | null;
        gnr?: number;
        bnr?: number;
        fnr?: number;
        snr?: number;
        teigid?: number;
        includeSeksjoner?: boolean;
        includeGeometries?: boolean;
        includeGiplanData?: boolean;
        includeArealUtenPlan?: boolean;
        buffer?: number;
        lang?: string | null;
    }): CancelablePromise<MappedPlanAnalyseResponse> {
        return __request(this.config, {
            method: 'GET',
            path: `/planer`,
            query: {
                Knr: knr,
                Gnr: gnr,
                Bnr: bnr,
                Fnr: fnr,
                Snr: snr,
                Teigid: teigid,
                IncludeSeksjoner: includeSeksjoner,
                IncludeGeometries: includeGeometries,
                IncludeGiplanData: includeGiplanData,
                IncludeArealUtenPlan: includeArealUtenPlan,
                Buffer: buffer,
                Lang: lang,
            },
        });
    }

    /**
     * @returns MappedPlanAnalyseResponse
     * @throws ApiError
     */
    public planerPostPlaner({
        requestBody,
    }: {
        requestBody: PlanerRequest;
    }): CancelablePromise<MappedPlanAnalyseResponse> {
        return __request(this.config, {
            method: 'POST',
            path: `/planer`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns MappedPlanAnalyseResponse
     * @throws ApiError
     */
    public planerGetPlanerByMatrikkelenhet({
        matrikkelnummer,
        includeGeometries,
        includeGiplanData,
        includeArealUtenPlan,
        buffer,
        lang,
    }: {
        matrikkelnummer?: string | null;
        includeGeometries?: boolean;
        includeGiplanData?: boolean;
        includeArealUtenPlan?: boolean;
        buffer?: number;
        lang?: string | null;
    }): CancelablePromise<MappedPlanAnalyseResponse> {
        return __request(this.config, {
            method: 'GET',
            path: `/planer/matrikkelenhet`,
            query: {
                Matrikkelnummer: matrikkelnummer,
                IncludeGeometries: includeGeometries,
                IncludeGiplanData: includeGiplanData,
                IncludeArealUtenPlan: includeArealUtenPlan,
                Buffer: buffer,
                Lang: lang,
            },
        });
    }

    /**
     * @returns MappedPlanAnalyseResponse
     * @throws ApiError
     */
    public planerGetPlanerByTeig({
        teigIdent,
        includeGeometries,
        includeGiplanData,
        includeArealUtenPlan,
        buffer,
        lang,
    }: {
        teigIdent: number;
        includeGeometries?: boolean;
        includeGiplanData?: boolean;
        includeArealUtenPlan?: boolean;
        buffer?: number;
        lang?: string | null;
    }): CancelablePromise<MappedPlanAnalyseResponse> {
        return __request(this.config, {
            method: 'GET',
            path: `/planer/teig/${teigIdent}`,
            query: {
                IncludeGeometries: includeGeometries,
                IncludeGiplanData: includeGiplanData,
                IncludeArealUtenPlan: includeArealUtenPlan,
                Buffer: buffer,
                Lang: lang,
            },
        });
    }

    /**
     * @returns MappedPlanAnalyseResponse
     * @throws ApiError
     */
    public planerGetPlanerByGeometry({
        requestBody,
    }: {
        requestBody: PlanerByGeometryRequest;
    }): CancelablePromise<MappedPlanAnalyseResponse> {
        return __request(this.config, {
            method: 'POST',
            path: `/planer/bygeometry`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
