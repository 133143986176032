// Adds some default styling to unstyled mui components
// https://mui.com/material-ui/customization/palette/
// Should not be used directly, see Figma for color usage
import {PaletteOptions} from '@mui/material';
import {semanticColorsDark} from './semanticColorsDark';
import {semanticColorsLight} from './semanticColorsLight';

export const paletteColorsLight: PaletteOptions = {
  primary: {
    main: semanticColorsLight.button.primary.fill,
    contrastText: semanticColorsLight.button.primary.text,
  },
  secondary: {
    main: semanticColorsLight.backgrounds.component.fill,
    contrastText: semanticColorsLight.text.default,
  },

  error: {
    main: semanticColorsLight.error.fill,
    contrastText: semanticColorsLight.text.errorInverted,
  },
};

export const paletteColorsDark: PaletteOptions = {
  primary: {
    main: semanticColorsDark.button.primary.fill,
    contrastText: semanticColorsDark.button.primary.text,
  },
  secondary: {
    main: semanticColorsDark.button.secondary.fill,
    contrastText: semanticColorsDark.button.secondary.text,
  },
  error: {
    main: semanticColorsDark.error.fill,
    contrastText: semanticColorsDark.text.errorInverted,
  },
};
