import {Map} from 'mapbox-gl';
import {MapboxGLButtonControl} from './MapboxGLButtonControl';
import {FullscreenButton} from './types';

export const addFullscreenButton = (
  fullscreenBtn: FullscreenButton,
  fullscreenIsActiveRef: React.MutableRefObject<boolean>,
  setFullscreenIsActive: (boolean: boolean) => void,
  map: Map,
) => {
  const fullscreenIsActive = fullscreenIsActiveRef.current;
  const domId = 'fullscreen_btn';
  const control = new MapboxGLButtonControl(
    fullscreenIsActive ? 'mapboxgl-ctrl-shrink' : 'mapboxgl-ctrl-fullscreen',
    fullscreenIsActive ? 'Lukk fullskjerm' : 'Aktiver fullskjerm',
    () => handelFullscreenChange(domId, fullscreenIsActive, fullscreenBtn.onClick, setFullscreenIsActive, map),
    domId,
  );
  map.addControl(control, fullscreenBtn.position);
  document.addEventListener(
    'keydown',
    (e) => {
      if (e.keyCode === 27 && fullscreenIsActive)
        handelFullscreenChange(domId, fullscreenIsActive, fullscreenBtn.onClick, setFullscreenIsActive, map);
    },
    false,
  );
};

const handelFullscreenChange = (
  domId: string,
  fullscreenIsActive: boolean,
  onClickCallback: () => void,
  setFullscreenIsActive: (boolean: boolean) => void,
  map: Map,
) => {
  fullscreenIsActive ? setFullscreenIsActive(false) : setFullscreenIsActive(true);
  //Update class to change icon
  const btn = document.getElementById(domId);
  if (btn) {
    btn.className = !fullscreenIsActive ? 'mapboxgl-ctrl-shrink' : 'mapboxgl-ctrl-fullscreen';
    btn.title = !fullscreenIsActive ? 'Avslutt fullskjerm' : 'Aktiver fullskjerm';
  }

  onClickCallback();
  map.resize();
};
