// keeps first instance of each id and discards subsequent duplicates
export function ensureUniqueId<T extends {id: string}>(array: T[]): T[] {
    const uniqueIds = new Set<string>();
    return array.filter((item) => {
        if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            return true;
        }
        return false;
    });
}
