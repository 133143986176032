import {Configuration, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import {TokenType} from '../getApiTokenFetchFunction';
import {setActiveAccountOnApp} from '../setActiveAccountOnApp';
import {AuthData} from '../types';

export const createAndInitializeInstance = async (configuration: Configuration) => {
  const instance = await PublicClientApplication.createPublicClientApplication(configuration);

  setActiveAccountOnApp(instance);
  return instance;
};

export type AuthConfigurationBase = {
  clientId: string;
  msalApiScopes: string[];
  setupNorkartProfile: string;
  isMultiCustomerContext?: boolean;
  applicationBackendUrl?: string;
  tokenType?: TokenType;
};

export const getAuthData = async (
  config: AuthConfigurationBase,
  msalApp: Promise<IPublicClientApplication>,
): Promise<AuthData> => ({
  setupNorkartProfile: config.setupNorkartProfile,
  msalApiScopes: config.msalApiScopes,
  msalApp: await msalApp,
  isMultiCustomerContext: config.isMultiCustomerContext,
  applicationBackendUrl: config.applicationBackendUrl,
  tokenType: config.tokenType,
});
