//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const ChevronDownIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M5 9.41003L11.97 16.38L12 16.35L12.03 16.38L19 9.41003L17.59 8L12 13.58L6.40997 8L5 9.41003Z' style={props.secondaryStyle} />
        </g>
    ),
    'ChevronDownIcon', // aria-label
);
