//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const SortingDownIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M7.99994 5.82844L5.9805 7.84788L4.56628 6.43367L8.99995 2L13.4336 6.43367L12.0194 7.84788L9.99994 5.82841L9.99994 13H7.99994L7.99994 5.82844Z' style={props.secondaryStyle} />
            <path d='M14.0001 18.1716L11.9806 16.1521L10.5664 17.5663L15.0001 22L19.4337 17.5663L18.0195 16.1521L16.0001 18.1716L16.0001 11L14.0001 11L14.0001 18.1716Z' style={props.primaryStyle} />
        </g>
    ),
    'SortingDownIcon', // aria-label
);
