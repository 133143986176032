import {ButtonProps, ButtonTypeMap, Button as MuiButton, styled} from '@mui/material';
import React, {forwardRef} from 'react';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {getSecureUrl} from '@norkart/validation-url';
import {toiButtonStyle} from './toiButtonStyle';

export type ToiButtonVariant = 'contained' | 'outlined' | 'text';
export type ToiButtonColor = 'primary' | 'secondary' | 'error';
export type ToiButtonSize = 'medium' | 'large';

declare module '@mui/material/Button' {
  // interface ButtonPropsVariantOverrides {
  //   variant?: ToiButtonVariant;
  //   size?: ToiButtonSize;
  //   color?: ToiButtonColor;
  // }
  interface ButtonPropsVariantOverrides {
    text: true;
  }
}

type OwnProps = {
  variant?: ToiButtonVariant;
  size?: ToiButtonSize;
  children?: React.ReactNode;
  color?: ToiButtonColor;
};

type DefaultComponent = ButtonTypeMap['defaultComponent'];

type RefType = HTMLElementTagNameMap[DefaultComponent];

export type ToiButtonProps = ButtonProps<DefaultComponent, OwnProps>;

const ToiButtonStyled = styled(MuiButton)(toiButtonStyle);

export const ToiButton = forwardRef<RefType, ToiButtonProps>(
  ({href, variant = 'contained', ...props}, forwardedRef) => {
    return <ToiButtonStyled ref={forwardedRef} variant={variant} href={getSecureUrl(href)} disableRipple {...props} />;
  },
) as OverridableComponent<ButtonTypeMap<OwnProps>>;

export default ToiButton;
