import {type PaletteMode} from '@mui/material';
import {forwardRef, SVGProps} from 'react';

type IconSize = 'small' | 'default' | 'large' | `${number}${'px' | 'em' | 'rem'}` | number;

interface InternalToiIconProps extends SVGProps<SVGSVGElement> {
  size?: IconSize;
  mode?: PaletteMode;
  color?: string;
  fillColor?: string;
  invertedColor?: string;
}

export interface ToiIconProps extends InternalToiIconProps {
  title?: string;
  desc?: string;
}

const getSizeForSVG = (size: IconSize): string | number => {
  if (typeof size === 'number') {
    return size > 0 ? size * 24 : 24; // Prevents negative icon size
  }

  switch (size) {
    case 'small':
      return '20px';
    case 'default':
      return '24px';
    case 'large':
      return '32px';
    default:
      return size;
  }
};

function useVars({
  color,
  fillColor,
  invertedColor,
}: Pick<InternalToiIconProps, 'color' | 'fillColor' | 'invertedColor'>) {
  const overrides = {
    primary: fillColor,
    secondary: color,
    inverted: invertedColor,
  };

  return Object.entries(overrides).reduce<Record<string, string>>((acc, [key, value]) => {
    if (value) {
      acc[`--toi-palette-icons-${key}`] = value;
    }
    return acc;
  }, {});
}

export const ToiIcon = forwardRef<SVGSVGElement, InternalToiIconProps>(
  ({mode, size = 'default', color, fillColor, invertedColor, ...props}, ref) => {
    const vars = useVars({color, fillColor, invertedColor});
    const sizeForSVG = getSizeForSVG(size);

    return (
      <svg
        data-mui-color-scheme={mode}
        style={vars}
        ref={ref}
        width={sizeForSVG}
        height={sizeForSVG}
        viewBox='0 0 24 24'
        preserveAspectRatio='xMidYMid meet'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      />
    );
  },
);
