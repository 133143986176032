//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppDeleteRecycleIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M15 0V2H21V4H3V2H9V0H15ZM6.54001 24H17.41L20 21.41V5H18V20.59L16.59 22H7.37L6 20.61V5H4V21.4301L6.54001 24Z' style={props.secondaryStyle} />
            <path fillRule='evenodd' clipRule='evenodd' d='M9 20H11V7H9V20ZM13 20H15V7H13V20Z' style={props.primaryStyle} />
        </g>
    ),
    'AppDeleteRecycleIcon', // aria-label
);
