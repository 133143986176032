import {RedirectRequest, SilentRequest} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import {
    AzureADAuthConfiguration,
    useIsLoggedIn,
    useLoginPopupAzureAD,
    useLogoutPopup,
    useNkAuth,
} from '@norkart/nk-auth';
import arealplanerConfig from '../../config';

const clientId = arealplanerConfig.planregister.client_id;
const authority = arealplanerConfig.planregister.authority;
const setupNorkartProfile = arealplanerConfig.planregister.profile;
const apiGeneralAccessScope = arealplanerConfig.planregister.api_scope;
export const azureADAuthProviderConfiguration: AzureADAuthConfiguration = {
    clientId,
    authority,
    msalApiScopes: [apiGeneralAccessScope],
    setupNorkartProfile,
    isMultiCustomerContext: false,
};

const loginRequest: RedirectRequest = {
    scopes: azureADAuthProviderConfiguration.msalApiScopes,
};

const silentLoginRequest: SilentRequest = {
    scopes: azureADAuthProviderConfiguration.msalApiScopes,
};

export function useAuth() {
    const loginNkmsal = useLoginPopupAzureAD(loginRequest);
    const logoutNkmsal = useLogoutPopup();
    const userData = useMsal();
    const isLoggedIn = useIsLoggedIn();
    const nkAuth = useNkAuth();

    const login = async () => {
        await loginNkmsal();
    };

    const logout = async () => {
        await logoutNkmsal();
    };

    const silentLogin = async () => {
        await nkAuth.authData.msalApp.ssoSilent(silentLoginRequest);
        const account = nkAuth.authData.msalApp.getAllAccounts()[0];
        nkAuth.authData.msalApp.setActiveAccount(account);
    };

    const getUserName = (): string => {
        const userAccount = userData.accounts[0];
        return userAccount
            ? (userAccount.name ?? userAccount.username)
            : 'Bruker';
    };

    return {
        silentLogin,
        login,
        logout,
        getUserName,
        isLoggedIn,
    };
}
