import {colors} from './colors';
import {SemanticColors} from './types';

export const semanticColorsDark: SemanticColors = {
  brand: {
    logo: colors.green[50],
    wordmark: colors.greyscale[5],
  },
  text: {
    default: colors.greyscale[5],
    inverted: colors.greyscale[80],
    placeholder: colors.greyscale[40],
    success: colors.green[50],
    warning: colors.yellow[5],
    error: colors.red[5],
    errorInverted: colors.red[90],
  },
  links: {
    default: colors.green[30],
    hover: colors.green[40],
    black: colors.greyscale[5],
    blackHover: colors.greyscale[20],
  },
  focus: {
    border: colors.greyscale[5],
    inverted: colors.greyscale[80],
  },
  bars: {
    fill: colors.greyscale[70],
    handle: colors.green[60],
    active: colors.green[30],
    warning: colors.yellow[30],
  },
  divider: {
    green: colors.green[60],
    grey: colors.greyscale[60],
    black: colors.greyscale[30],
  },
  backgrounds: {
    generic: {
      default: colors.greyscale[100],
      grey: colors.greyscale[100],
      panel: colors.greyscale[90],
      panelOnGrey: colors.greyscale[90],
      green: colors.green[100],
      blue: colors.blue[80],
      orange: colors.yellow[100],
    },
    component: {
      fill: colors.greyscale[80],
      highlighted: colors.green[100],
      hover: colors.greyscale[80],
      overlay: colors.greyscale[70],
      active: colors.green[50],
      activeHover: colors.green[60],
      selected: colors.green[100],
      warning: colors.yellow[100],
    },
  },
  border: {
    component: colors.greyscale[70],
    hover: colors.greyscale[50],
    input: colors.greyscale[10],
    inputHover: colors.green[20],
    active: colors.green[50],
    error: colors.red[50],
    errorHover: colors.red[90],
  },
  error: {
    fill: colors.red[10],
    fillInverted: colors.red[80],
    hover: colors.red[20],
    hoverInverted: colors.red[60],
  },
  disabled: {
    fill: colors.greyscale[60],
    text: colors.greyscale[40],
    textOnWhite: colors.greyscale[50],
  },
  signal: {
    foreground: {
      normal: colors.greyscale[30],
      info: colors.blue[20],
      success: colors.green[30],
      warning: colors.yellow[20],
      error: colors.red[20],
    },
    background: {
      normal: colors.greyscale[70],
      normalHover: colors.greyscale[80],
      info: colors.blue[70],
      infoHover: colors.blue[80],
      success: colors.green[80],
      successHover: colors.green[90],
      warning: colors.yellow[100],
      warningHover: colors.yellow[100],
      error: colors.red[70],
      errorHover: colors.red[80],
    },
  },
  button: {
    primary: {
      fill: colors.green[50],
      hover: colors.green[40],
      text: colors.greyscale[80],
    },
    secondary: {
      fill: colors.green[80],
      hover: colors.green[90],
      text: colors.greyscale[5],
    },
    tertiary: {
      fill: colors.green[100],
      hover: colors.green[90],
      text: colors.greyscale[10],
    },
    outline: {
      fill: colors.green[60],
      hover: colors.green[70],
      text: colors.greyscale[5],
      bg: colors.greyscale[80],
    },
    map: {
      fill: colors.greyscale[30],
      hover: colors.green[10],
    },
  },
  icons: {
    primary: colors.green[50],
    secondary: colors.greyscale[5],
    tertiary: colors.greyscale[40],
    inverted: colors.greyscale[5],
  },
  pictograms: {
    stroke: colors.greyscale[5],
    background: colors.greyscale[60],
    fill: colors.greyscale[40],
    highlight1: colors.green[40],
    highlight2: colors.red[40],
    highlight3: colors.blue[40],
  },
  illustrations: {
    stroke: '#FFFFFF',
    white: '#C0C0C0',
    lightGray: '#68686A',
    gray: '#87888A',
    lightGreen: '#1AA164',
    green: '#61C496',
    lightBlue: '#3D80CE',
    blue: '#75ADE5',
    lightRed: '#DF4742',
    red: '#F47777',
    lightPurple: '#8D6CDC',
    purple: '#AC98EE',
    lightYellow: '#FFB300',
    yellow: '#FFCC52',
  },
};
