import {Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {themeIsLightMode} from '../utils/themeIsLightMode';
import {getVariantStyle} from '../ToiTypography/toiTypographyStyle';
import {ToiLinkColor, ToiLinkVariant} from './ToiLink';

export const toiLinkStyle = (
  theme: Theme,
  variant: ToiLinkVariant,
  uppercase?: boolean,
  color?: ToiLinkColor,
  definition?: boolean,
  inline?: boolean,
) => {
  const palette = theme.palette;
  const isLightMode = themeIsLightMode(theme);

  const defaultStyle = {
    fontFamily: 'figtree',
    display: 'flex',
    gap: '8px',
    width: 'fit-content',

    '&.Mui-focusVisible': {
      borderRadius: '4px',
      outline: `1px dashed ${isLightMode ? palette.black[100] : palette.secondary.main}`,
    },
  };

  const getTextColor = (color?: ToiLinkColor) => {
    if (color === 'secondary') {
      return isLightMode ? palette.black[100] : palette.white;
    }
    return isLightMode ? palette.primary[100] : palette.primary[40];
  };

  const getHoverColor = (color?: ToiLinkColor) => {
    if (color === 'secondary') {
      return isLightMode ? palette.black[70] : palette.black[20];
    }
    return isLightMode ? palette.primary[130] : palette.primary[30];
  };

  const variantStyle = (color?: ToiLinkColor, definition?: boolean, inline?: boolean) => {
    if (definition || inline) {
      return standardStyle();
    }

    const variantStyle = getVariantStyle(variant, uppercase ? {uppercase: true} : {});
    const iconSize = getIconSizeFromVariant(variant);
    const textColor = getTextColor(color);
    const hoverColor = getHoverColor(color);

    if (!palette.primary) {
      console.error('MISSING PRIMARY PALETTE, TRY IMPORTING AND USING TOI-COLORS');
      return standardStyle();
    }

    return {
      color: textColor,
      textDecorationColor: textColor,
      svg: {
        height: iconSize,
        width: iconSize,
        // Color is only applied to mui icons
        color: textColor,
        margin: 0,
      },
      '&:hover': {
        color: hoverColor,
        textDecorationColor: hoverColor,
        svg: {
          // Color is only applied to mui icons
          color: hoverColor,
        },
      },
      ...variantStyle,
    };
  };

  const definitionStyle = (definition?: boolean) => {
    if (definition) {
      return {
        color: isLightMode ? palette.common.black : palette.common.white,
        textDecoration: 'none',
        borderBottom: isLightMode ? '1px dashed' + palette.common.black : '1px dashed' + palette.common.white,
        padding: 'unset',
        lineHeight: '120%',
        marginBottom: '1px', // offsett the outlined added on focus
        '&:hover': {
          background: isLightMode ? palette.black[20] : palette.black[70],
        },
        '&:focus': {
          background: isLightMode ? palette.black[20] : palette.black[70],
          borderRadius: '4px',
          border: 'none',
          outline: `1px dashed ${isLightMode ? palette.black[100] : palette.secondary.main}`,
          marginBottom: '-1px', // offsett the outline
        },
        '&:active': {
          background: isLightMode ? palette.primary[30] : palette.primary[100],
        },
      };
    }
    return standardStyle();
  };

  const inlineStyle = (inline?: boolean) => {
    if (inline) {
      return {
        display: 'inline-block',
      };
    }
    return standardStyle();
  };

  const standardStyle = () => ({});

  return merge(
    {},
    defaultStyle,
    variantStyle(color, definition, inline),
    definitionStyle(definition),
    inlineStyle(inline),
  );
};

const getIconSizeFromVariant = (variant: ToiLinkVariant) => {
  switch (variant) {
    case 'h1':
      return 50;
    case 'h2':
      return 40;
    case 'h3':
      return 30;
    case 'h4':
      return 25;
    case 'body1':
      return 30;
    default:
      return 20;
  }
};
