import {forwardRef} from 'react';
import {styled, DialogTitle as MuiDialogTitle} from '@mui/material';
import ToiTypography, {ToiTypographyProps} from '../../ToiTypography/ToiTypography';
import {toiDialogTitleStyle} from './toiDialogTitleStyle';

const StyledDialogTitle = styled(MuiDialogTitle)(({theme}) => toiDialogTitleStyle(theme));

export const ToiDialogTitle = forwardRef<HTMLDivElement, ToiTypographyProps>((props, ref) => {
  const {variant = 'h3', component = 'h1', ...restProps} = props;
  return (
    <StyledDialogTitle ref={ref}>
      <ToiTypography variant={variant} component={component} {...restProps}></ToiTypography>
    </StyledDialogTitle>
  );
});
