import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';

type DialogGenericErrorProps = {
    open: boolean;
    title: string;
    content: string;
    typeOfMessage: 'warning' | 'error' | 'info' | 'success';
    handleClose: () => void;
};

function DialogGenericMessage({...props}: DialogGenericErrorProps) {
    return (
        <Dialog open={props.open}>
            <DialogTitle sx={{gap: 1, display: 'flex', alignItems: 'center'}}>
                <ErrorIcon color={props.typeOfMessage} fontSize='large' />
                <Typography variant='subtitle1'>{props.title}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{paddingLeft: 1}}>{props.content}</Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    paddingRight: '24px',
                    paddingBottom: '16px',
                }}
            >
                <Button variant='contained' onClick={() => props.handleClose()}>
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default DialogGenericMessage;
