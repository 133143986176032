import {cacheCurrentCustomerContext, getCachedCurrentCustomerContext} from './cache/customerContextCache';
import {CustomerContext} from './types';

const matchCustomerContexts = (customerContexts: CustomerContext[] | undefined, customerContextId: string) =>
  customerContexts?.find(({CustomerId}) => CustomerId === customerContextId);

export const getDefaultMultiCustomerContext = (
  customerContexts: CustomerContext[] | undefined,
  predefinedCustomerContextId: string | undefined,
) => {
  // Return first customer context if none is selected/cached/predefined in query parameter, or if the requested customerId don't exist in the available customer contexts
  const firstCustomerContext = customerContexts ? customerContexts[0] : undefined;
  const cachedCustomerContextId = getCachedCurrentCustomerContext();

  // Return customer context to match the query parameter if the customer context is valid
  if (predefinedCustomerContextId) {
    const predefinedCustomerContext = matchCustomerContexts(customerContexts, predefinedCustomerContextId);
    if (predefinedCustomerContext) {
      return predefinedCustomerContext;
    }
  }

  // Return cached customer context if the cached customer context is valid
  if (cachedCustomerContextId) {
    const cachedCustomerContext = matchCustomerContexts(customerContexts, cachedCustomerContextId);
    if (cachedCustomerContext) {
      return cachedCustomerContext;
    }
  }

  return firstCustomerContext;
};

export const changeCustomerContext = (
  customerContext: CustomerContext,
  availableCustomerContexts: CustomerContext[],
  setCustomerContext: (customerContext: CustomerContext) => void,
  isMultiCustomerContext: boolean,
) => {
  if (!isMultiCustomerContext) {
    throw new Error('The application is not multi customer context.');
  }

  if (!customerContext.CustomerId) {
    throw new Error('The provided customer context does not include customerId');
  }

  const newCustomerContext = matchCustomerContexts(availableCustomerContexts, customerContext.CustomerId);

  if (!newCustomerContext) {
    throw new Error('The provided customer context does not match any of the available customer contexts.');
  }

  cacheCurrentCustomerContext(customerContext.CustomerId);
  setCustomerContext(customerContext);
};
