import {IPublicClientApplication} from '@azure/msal-browser';
import {getTokenFetchFunction, TokenType} from './getApiTokenFetchFunction';
import {AuthData} from './types';

export const getHeaderGenerator = (
  msalApp: IPublicClientApplication,
  authData: AuthData,
  tokenType: TokenType = 'accessToken',
  customerContextId?: string,
) => {
  const getToken = getTokenFetchFunction(msalApp, authData, tokenType);

  return async () => {
    const baseHeader: Record<string, string> = {
      Authorization: `Bearer ${await getToken()}`,
      'X-WAAPI-Profile': authData.setupNorkartProfile,
    };

    if (customerContextId) {
      return {...baseHeader, 'X-WAAPI-CONTEXT': customerContextId ?? ''};
    }

    return baseHeader;
  };
};
