import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useIsLoggedIn} from '@norkart/nk-auth';
import {
    ToiAppBarTextButton,
    ToiStack,
} from '@norkart/toi-deprecated-components';
import {AppPlusIcon, MediaDocumentsIcon} from '@norkart/toi-deprecated-icons';
import useReportTypes from '../../../features/reports/Reports/useReportTypes';
import useSelectedKundeId from '../../../hooks/kunder/useSelectedKundeId';
import {showDialog} from '../../../store/dialog/actions';
import useCan from '../../../utils/auth/useCan';

function TopbarActions() {
    const can = useCan();
    const kundeId = useSelectedKundeId();
    const dispatch = useDispatch();
    const reportTypes = useReportTypes();
    const navigate = useNavigate();
    const isLoggedIn = useIsLoggedIn();

    const handleNyPlan = () => {
        dispatch(
            showDialog({
                dialogType: 'EDIT_PLAN',
                dialogProps: {plan: {}},
            })
        );
    };

    const handleRapporter = () => {
        const firstReport = reportTypes.filter((rt) => rt.tilgang);

        firstReport.length > 0
            ? navigate(
                  `/${kundeId}/rapporter/${firstReport[0].Navn.toLowerCase()}`
              )
            : navigate(`/${kundeId}/rapporter`);
    };

    const handleNyheter = () => {
        navigate('/nyheter');
    };

    const handleInnstillinger = () => {
        navigate(`${kundeId}/innstillinger/tilganger`);
    };

    const dropdownItems = useMemo(
        () => [
            ...(can.view ? [{text: 'Nyheter', action: handleNyheter}] : []),
            ...(can.edit
                ? [{text: 'Innstillinger', action: handleInnstillinger}]
                : []),
        ],
        [handleNyheter, handleInnstillinger]
    );

    if (!kundeId || !isLoggedIn) return null;

    return (
        <ToiStack direction='row'>
            {can.edit && (
                <ToiAppBarTextButton
                    title='Ny plan'
                    action={handleNyPlan}
                    type={'icon'}
                    icon={<AppPlusIcon size={'default'} />}
                />
            )}
            {can.view && (
                <ToiAppBarTextButton
                    title='Rapporter'
                    action={handleRapporter}
                    type={'icon'}
                    icon={<MediaDocumentsIcon blackWhite />}
                />
            )}
            <ToiAppBarTextButton
                title='Mer...'
                type='dropdown'
                dropdownItems={dropdownItems}
            />
        </ToiStack>
    );
}

export default TopbarActions;
