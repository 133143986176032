import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import {noOp} from './hooks/meh';
import {AuthData, TokenFetcher} from './types';

// Guest members of an AD must set the authority to the tenant of the logged in user (otherwise it will get from home tenant)
const setGuestADAuthority = (msalApp: IPublicClientApplication) => {
  const account = msalApp.getActiveAccount();
  if (account != null) {
    // the homeAccountId seems to be oid.tid, so we can check if the localAccount is part of the homeAccountId to find if we are a guest user.
    if (!account.homeAccountId.includes(account.localAccountId)) {
      // we have a guest account that belongs to antoher AD, we therefore need to request access tokens from the AD where the guest is registered.
      // If we use the common endpoint to get access token we will get a token for the users home AD.
      const config = msalApp.getConfiguration();
      if (!config.auth.authority.includes(account.tenantId)) {
        config.auth.authority = config.auth.authority.replace('common', account.tenantId);
      }
    }
  }
};

const getAuthenticationResult = async (
  msalApp: IPublicClientApplication,
  request: SilentRequest,
): Promise<AuthenticationResult> => {
  const accounts = msalApp.getAllAccounts();
  if (accounts.length > 0) {
    try {
      await noOp();
      setGuestADAuthority(msalApp);
      const res = await msalApp.acquireTokenSilent(request);
      return res;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const res = await msalApp.acquireTokenPopup(request);
        return res;
      }
    }
  }

  throw new Error('Something went wrong during acquiring of token. You are not logged in.');
};

const getAccessToken = async (msalApp: IPublicClientApplication, request: SilentRequest) => {
  const authenticationResult = await getAuthenticationResult(msalApp, request);
  return authenticationResult.accessToken;
};

const getIdToken = async (msalApp: IPublicClientApplication, request: SilentRequest) => {
  const authenticationResult = await getAuthenticationResult(msalApp, request);
  return authenticationResult.idToken;
};

export type TokenType = 'accessToken' | 'idToken';

/**
 * @summary Resolves a function that will fetch a token on the users behalf.
 *
 * @param msalApp The instance of MSAL Application Client to perform the request with.
 * @param authdata Configuration object
 * @param tokenType The type of JWT that should be returned
 *
 * @returns a TokenFetcher that can be called to get a string representation of the JWT
 */
export const getTokenFetchFunction = (
  msalApp: IPublicClientApplication,
  authdata: AuthData,
  tokenType: TokenType,
): TokenFetcher => {
  const resolver: TokenFetcher = async (request?: SilentRequest) => {
    request ??= {scopes: []};

    request.scopes = request.scopes.concat(authdata.msalApiScopes); // don't know if we want this or not, but let's go with it for now
    switch (tokenType) {
      case 'accessToken':
        return await getAccessToken(msalApp, request);
      case 'idToken':
        return await getIdToken(msalApp, request);
      default:
        throw new Error(`No implementation for tokentype ${tokenType}`);
    }
  };

  return resolver;
};
