//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const ArrowLeftIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M8.83192 12.9972L11.6305 15.7958L10.2163 17.21L5 11.9937L10.2163 6.77747L11.6305 8.19168L8.82493 10.9972L19 10.9972V12.9972L8.83192 12.9972Z' style={props.secondaryStyle} />
        </g>
    ),
    'ArrowLeftIcon', // aria-label
);
