import {Configuration} from '@azure/msal-browser';
import {LoggerOptions} from '@azure/msal-common';
import {AzureADAuthConfiguration} from '../components/Providers/NkAzureADAuthProvider';
import {createAndInitializeInstance, getAuthData} from './msalApp';

const getMsalAppAzureAD = async (
  clientId: string,
  authority: string,
  redirectUri?: string,
  loggerOptions?: LoggerOptions,
) => {
  const configuration: Configuration = {
    auth: {
      clientId,
      authority,
      redirectUri: redirectUri ? redirectUri : window.location.origin,
    },
    system: {
      loggerOptions: loggerOptions,
    },
  };

  return createAndInitializeInstance(configuration);
};

export const getAzureADAuthData = async (config: AzureADAuthConfiguration) =>
  getAuthData(config, getMsalAppAzureAD(config.clientId, config.authority, config.redirectUri, config.loggerOptions));
