import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
  DividerTypeMap as MuiDividerTypeMap,
  styled,
} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React from 'react';
import {toiDividerStyle} from './toiDividerStyle';

export type DividerSize = 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl';

type OwnProps = {
  size?: DividerSize;
  color?: 'green' | 'black' | string; // colors divider itself and children (text)
};

type ToiDividerProps<C extends React.ElementType> = Omit<
  MuiDividerProps<C, {component?: C; ref?: React.Ref<C>}>,
  keyof OwnProps
> &
  OwnProps;

type ToiDividerType = OverridableComponent<{
  props: Omit<MuiDividerTypeMap<OwnProps>['props'], keyof OwnProps> & OwnProps;
  defaultComponent: MuiDividerTypeMap<OwnProps>['defaultComponent'];
}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledDivider = styled(MuiDivider)<OwnProps>(({theme, orientation, color, size}): any => {
  return toiDividerStyle(theme, orientation, color, size);
});

export const ToiDivider = React.forwardRef(function ToiDividerInner<D extends React.ElementType>(
  props: ToiDividerProps<D>,
  ref: React.ComponentPropsWithRef<D>['ref'],
) {
  const {...rest} = props;

  return <StyledDivider ref={ref} {...rest} />;
}) as ToiDividerType;

export default ToiDivider;
