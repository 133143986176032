import {TextField, TextFieldProps, Theme, styled} from '@mui/material';
import {merge} from 'lodash-es';
import {themeIsLightMode} from '../utils/themeIsLightMode';
import {getVariantStyle} from '../ToiTypography/toiTypographyStyle';
import {LabelVariant, ToiTextFieldVariant} from './ToiTextField';

export const StyledTextField: React.FC<
  TextFieldProps & {variantStyle: ToiTextFieldVariant; labelVariant?: LabelVariant}
> = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'variantStyle' && prop !== 'labelVariant',
})<TextFieldProps & {variantStyle: ToiTextFieldVariant; labelVariant?: LabelVariant}>(({
  theme,
  error,
  disabled,
  labelVariant,
  variantStyle,
}) => {
  return variantStyle === 'map'
    ? {
        ...toiTextFieldStyle({...theme, palette: {...theme.palette, mode: 'light'}}, error, disabled, labelVariant),
        '& .MuiOutlinedInput-root': {outline: 'none', borderRadius: '4px', background: theme.palette.white},
      }
    : toiTextFieldStyle(theme, error, disabled, labelVariant);
});

export const toiTextFieldStyle = (theme: Theme, error?: boolean, disabled?: boolean, labelVariant?: LabelVariant) => {
  const palette = theme.palette;
  const isLightMode = themeIsLightMode(theme);

  const setAttribsFromVariant = (labelVariant?: LabelVariant): Record<string, boolean> => {
    switch (labelVariant) {
      case 'light':
        return {light: true};
      case 'semibold':
        return {semibold: true};
      default:
        return {};
    }
  };

  const getInputBackgroundColor = (): string | undefined => {
    if (isLightMode) {
      if (disabled) {
        return palette.black[20];
      } else {
        return palette.common.white;
      }
    }

    if (disabled) {
      return palette.black[70];
    }
    return undefined;
  };

  const defaultStyle = {};

  const customStyle = () => ({
    '& .MuiInputLabel-root': {
      position: 'relative',
      color: error ? palette.error.main : isLightMode ? palette.black[100] : palette.grey[100],
      transformOrigin: 'unset',
      transform: 'unset',
      ...getVariantStyle('label', setAttribsFromVariant(labelVariant)),
      '&.Mui-focused': {
        color: error ? palette.error.main : isLightMode ? 'black!important' : 'white!important',
      },
    },

    '& fieldset': {
      //Remove the default border to create custom instead
      border: 'none',
    },

    '& .MuiOutlinedInput-root': {
      backgroundColor: getInputBackgroundColor(),
      outline: disabled
        ? undefined
        : `1px solid ${error ? palette.error.main : isLightMode ? palette.black[70] : palette.common.white}`,
      border: 'none',
      '&:hover': {
        outline: disabled
          ? 'none'
          : `2px solid ${error ? palette.error.main : isLightMode ? palette.black[70] : palette.common.white}`,
      },
      '&.Mui-focused': {
        outline: `2px solid ${error ? palette.error.main : palette.primary[70]}`,
      },
      '&:focus': {
        outline: `2px solid ${error ? palette.error.main : isLightMode ? palette.primary[70] : palette.common.white}`,
      },
    },
    '& input': {
      '&::placeholder': {
        color: isLightMode ? palette.black[70] : palette.black[30],
        opacity: 1,
      },
    },

    '& .MuiFormHelperText-root': {
      marginLeft: 'unset',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: isLightMode ? palette.error.main : palette.error[70],
    },
  });

  return merge({}, defaultStyle, customStyle());
};
