// Fra  "react-final-form-listeners": "^1.0.3" og gjort om til funksjonskomponent
import * as React from 'react';
import {Field} from 'react-final-form';

type OnChangeProps = {
    name: string;
    children: (value: any, previous: any) => void;
};

type Props = {
    children: (value: any, previous: any) => void;
    input: {
        value: any;
    };
};

function OnChangeState({input: {value}, children}: Props) {
    const [previous, setPrevious] = React.useState(value);

    React.useEffect(() => {
        if (value !== previous) {
            setPrevious(value);
            children(value, previous);
        }
    }, [value]);

    return null;
}

const OnChange = ({name, children}: OnChangeProps) => {
    return (
        <Field
            name={name}
            subscription={{value: true}}
            allowNull={true}
            render={(props) => <OnChangeState {...props} children={children} />}
        />
    );
};

export default OnChange;
