import {
  styled,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  TypographyTypeMap as MuiTypographyTypeMap,
  Theme,
  TypographyProps,
  TypographyOwnProps,
} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React from 'react';
import {useIsMobile} from '@norkart/design-responsive';
import {toiTypographyStyle} from './toiTypographyStyle';
import {validateTypographyAttributes} from './TypographyVariantValidator';

export type ToiTypographyVariant = NonNullable<TypographyProps['variant']>;

export type TypographyAttributes = {
  uppercase?: boolean;
  bold?: boolean;
  italic?: boolean;
  light?: boolean;
  semibold?: boolean;
  noSpacing?: boolean; // Has no effect. Needed to make typescript happy when resolving deprecated components to new components
};

export type OwnProps = TypographyAttributes;

export type ToiTypographyProps = MuiTypographyProps<'span', OwnProps>;

type ToiTypographyType = OverridableComponent<{
  props: TypographyOwnProps & OwnProps;
  defaultComponent: MuiTypographyTypeMap<OwnProps>['defaultComponent'];
}>;

export const getDefaultTypographyVariant = (theme: Theme): ToiTypographyVariant =>
  theme.components?.MuiTypography?.defaultProps?.variant ?? 'body2';

const getCustomVariantComponent = (variant: ToiTypographyVariant) => {
  if (variant === 'body3') {
    return 'p';
  } else if (variant === 'label') {
    return 'label';
  } else {
    return undefined;
  }
};

const StyledTypography = styled(MuiTypography, {
  shouldForwardProp: (prop) => !['uppercase', 'bold', 'italic', 'light', 'semibold'].includes(prop.toString()),
})<TypographyAttributes>(({theme, variant, uppercase, bold, italic, light, semibold}) => {
  const defaultVariant = getDefaultTypographyVariant(theme);
  const isMobile = useIsMobile();

  validateTypographyAttributes({
    variant: variant || defaultVariant,
    uppercase: uppercase,
    bold: bold,
    italic: italic,
    light: light,
    semibold: semibold,
  });

  return toiTypographyStyle(
    theme,
    variant ?? defaultVariant,
    {
      uppercase: uppercase,
      bold: bold,
      italic: italic,
      light: light,
      semibold: semibold,
    },
    isMobile,
  );
});

export const ToiTypography = React.forwardRef<HTMLSpanElement, ToiTypographyProps>((props, ref) => {
  const {children, ...restProps} = props;

  return (
    <StyledTypography
      component={
        props['component'] ? props['component'] : getCustomVariantComponent(props['variant'] as ToiTypographyVariant)
      }
      ref={ref}
      {...restProps}
    >
      {children}
    </StyledTypography>
  );
}) as ToiTypographyType;

export default ToiTypography;
