import {Experimental_CssVarsProvider as CssVarsProvider} from '@mui/material';
import {PropsWithChildren} from 'react';
import {toiTheme} from './toiTheme';

type Props = PropsWithChildren<{
  themeOverrides?: object;
}>;

export const ToiThemeProvider = ({children, themeOverrides = {}}: Props) => {
  return <CssVarsProvider theme={toiTheme(themeOverrides)}>{children}</CssVarsProvider>;
};

export default ToiThemeProvider;
