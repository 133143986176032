import * as React from 'react';
import {Typography} from '@mui/material';
import {useNavigate, useSearchParams} from 'react-router-dom';

export interface TabbableTableLink {
    text: string;
    url?: string;
    underline?: boolean;
    action?: (e) => any;
    addSearchParams?: boolean;
    externalUrl?: boolean;
    style?: React.CSSProperties;
}

const getTableRow = (element: HTMLElement | null) => {
    while (element && element.nodeName !== 'TR') {
        element = element.parentElement;
    }
    return element;
};

export function TabbableTableLink({
    text,
    underline,
    url,
    action,
    addSearchParams = false,
    externalUrl = false,
    style,
}: TabbableTableLink) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const row = getTableRow(e.currentTarget);
            const nextRow = row && (row.nextSibling as HTMLElement);
            const nextButton =
                nextRow &&
                nextRow.querySelector<HTMLButtonElement>(
                    'button.tabbable-table-link'
                );
            nextButton && nextButton.focus();
        }
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            const row = getTableRow(e.currentTarget);
            const prevRow = row && (row.previousSibling as HTMLElement);
            const prevButton =
                prevRow &&
                prevRow.querySelector<HTMLButtonElement>(
                    'button.tabbable-table-link'
                );
            prevButton && prevButton.focus();
        }
    };

    return (
        <button
            className='tabbable-table-link'
            style={style}
            type='button'
            onClick={(e) => {
                e.stopPropagation();
                if (url)
                    externalUrl
                        ? window.open(url)
                        : addSearchParams
                          ? navigate(url + '?' + searchParams.toString())
                          : navigate(url);
                if (action) action(e);
            }}
            onFocus={(e) => {
                const row = getTableRow(e.currentTarget);
                row && row.classList.add('active-row');
            }}
            onBlur={(e) => {
                const row = getTableRow(e.currentTarget);
                row && row.classList.remove('active-row');
            }}
            onKeyDown={handleKeyDown}
        >
            {underline ? (
                <Typography
                    component='span'
                    style={{textDecoration: 'underline'}}
                >
                    {text}
                </Typography>
            ) : (
                <Typography component='span'>{text}</Typography>
            )}
        </button>
    );
}

export default TabbableTableLink;
