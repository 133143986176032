// eslint-disable-next-line no-restricted-imports
import {createBreakpoints} from '@mui/system';
import {experimental_extendTheme as extendTheme} from '@mui/material/styles';
import {toiColorSchemes, type ToiPalette} from './toiPalette';
import {ToiVariables, toiVariables} from './toiVariables';
import './fonts.css';
import {typographyStyle} from './toiTypography';
import {toiBreakpoints} from './toiBreakpoints';
import {componentOverrides} from './componentOverrides';
import {mixins} from './mixins';

declare module '@mui/material/styles' {
  interface CssVarsThemeOptions {
    toi?: ToiVariables;
  }

  interface Theme {
    toi: ToiVariables & {palette: ToiPalette};
  }

  interface ThemeVars {
    toi: ToiVariables & {palette: ToiPalette};
  }

  interface BreakpointOverrides {
    mobile: true;
    mobilePortrait: true; //TODO: Remove portrait and landscape when migration is finished
    mobileLandscape: true;
    tablet: true;
    laptop: true;
    desktop: true;
    ultrawide: true;
  }
}

const spacing = (value: number | string): string => {
  if (typeof value === 'number') {
    return `${8 * value}px`; // default mui way
  }
  const lowerCase = value.toLowerCase();
  return `var(--toi-spacing-${lowerCase})`;
};

export const toiTheme = (themeOverrides: object) =>
  extendTheme(
    {
      cssVarPrefix: '',
      // Skips generating CSS variables for the theme`s 'toi' property. The spacing variables are defined in the `spacing.css` file
      shouldSkipGeneratingVar: (keys) =>
        ['typography', 'breakpoints', 'unstable_sxConfig'].includes(keys[0]) ||
        (keys[0] === 'toi' && keys[1] !== 'palette'),
      colorSchemes: toiColorSchemes,
      typography: typographyStyle,
      shape: {
        borderRadius: 0,
      },
      components: componentOverrides,
      toi: toiVariables,
      breakpoints: createBreakpoints(toiBreakpoints),
      mixins,
      spacing,
    },
    themeOverrides,
  );
