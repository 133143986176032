//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const ChevronUpIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path d='M19 14.97L12.03 8L12 8.03003L11.97 8L5 14.97L6.40997 16.38L12 10.8L17.59 16.38L19 14.97Z' style={props.secondaryStyle} />
        </g>
    ),
    'ChevronUpIcon', // aria-label
);
