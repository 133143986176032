import {ListItemText, styled} from '@mui/material';

export const ToiListItemText = styled(ListItemText)(({theme}) => ({
  margin: 0,
  '&.MuiListItemText-primary': {
    ...theme.typography.body2,
    fontWeight: 600,
  },
  '&.MuiListItemText-secondary': {
    ...theme.typography.body3,
  },
  '&.MuiListItemText-inset': {
    paddingLeft: '32px',
  },
}));
