import {
    ToiListItem,
    ToiListItemIcon,
    ToiListItemText,
} from '@norkart/toi-components';
import {ArrowRightIcon} from '@norkart/toi-icons';

export default function TrackingListItem({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <ToiListItem>
            <ToiListItemIcon>
                <ArrowRightIcon size={'small'} />
            </ToiListItemIcon>
            <ToiListItemText disableTypography={true}>
                {children}
            </ToiListItemText>
        </ToiListItem>
    );
}
