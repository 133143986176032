import {Box, Grid, Paper, Typography} from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import {FaseForSak, Milepel} from '../../../../hooks/faserForSak/types';
import useFaserForSak from '../../../../hooks/faserForSak/useFaserForSak';
import {getActiveSakId} from '../../../../store';
import {formatDate} from '../../../../utils/formatDate';
import SakSelector from '../../../sak/SakSelector/SakSelector';
import './PlanTimeline.css';

function PlanTimeline() {
    const statusClassMapping = {0: 'not-started', 1: 'started', 2: 'finished'};
    const selectedSakId = useSelector(getActiveSakId);

    const {
        data: faserForSak,
        isLoading,
        isError,
        isFetched,
    } = useFaserForSak(selectedSakId);

    const renderMilepel = (milepel: Milepel | null) =>
        milepel && (
            <Paper
                elevation={3}
                sx={(theme) => ({
                    padding: '6px 16px',
                    [theme.breakpoints.down('sm')]: {
                        padding: '4px 8px',
                    },
                    width: 'fit-content',
                })}
            >
                {milepel.milepelBeskrivelse || milepel.milepelNavn || ''}
            </Paper>
        );

    const renderTimelineItem = (faseForSak: FaseForSak, i: number) => {
        const step = i + 1;
        return (
            <div key={step}>
                <div className='row'>
                    <div className='content left'>
                        <Typography>{faseForSak.faseNavn}</Typography>
                    </div>
                    <div className='connector-container'>
                        {step != 1 && (
                            <span className='connector top vertical' />
                        )}
                        <span className='connector middle horizontal' />
                        <span className='connector bottom vertical' />
                    </div>
                    <div className='content right' />
                </div>
                <div className='row milestone'>
                    <div className='content left'>
                        <Typography>
                            {faseForSak.sluttMilepel?.milepelDato != null
                                ? formatDate(
                                      faseForSak.sluttMilepel?.milepelDato
                                  )
                                : faseForSak.startMilepel?.milepelDato != null
                                  ? formatDate(
                                        faseForSak.startMilepel?.milepelDato
                                    )
                                  : null}
                        </Typography>
                    </div>
                    <div
                        className={classNames(
                            'dot',
                            statusClassMapping[
                                faseForSak.faseStatus?.kodeverdi || 0
                            ]
                        )}
                    >
                        {step}
                    </div>
                    <div className='content right'>
                        {renderMilepel(faseForSak.sluttMilepel)}
                    </div>
                </div>
            </div>
        );
    };

    const renderTimeline = () => {
        if (isError)
            return (
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    Ingen tidsakse å vise for sak.
                </div>
            );
        if (isLoading && !faserForSak) return <LoadingSpinner />;
        if (isFetched && faserForSak)
            return (
                <Box
                    sx={(theme) => ({
                        width: '100%',
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                            padding: '2px 4px',
                        },
                    })}
                >
                    {faserForSak.map((p, i) => renderTimelineItem(p, i))}
                </Box>
            );
    };

    return (
        <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} md={2} style={{minWidth: '250px'}}>
                <SakSelector />
            </Grid>
            <Grid item={true} xs={12} md={8}>
                {renderTimeline()}
            </Grid>
        </Grid>
    );
}

export default PlanTimeline;
