import {Link, LinkOwnProps, LinkProps, SvgIconTypeMap, styled} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React, {ElementType} from 'react';
import {getSecureUrl} from '@norkart/validation-url';
import {getDefaultTypographyVariant, ToiTypographyVariant} from '../ToiTypography/ToiTypography';
import {validateTypographyAttributes, validateTypographyVariant} from '../ToiTypography/TypographyVariantValidator';
import {toiLinkStyle} from './ToiLinkStyle';

export type ToiLinkVariant = ToiTypographyVariant;
export type ToiLinkColor = 'primary' | 'secondary';

type OwnProps = {
  children?: React.ReactNode;
  variant?: ToiLinkVariant;
  uppercase?: boolean;
  color?: ToiLinkColor;
  definition?: boolean;
  inline?: boolean;
  StartIcon?: OverridableComponent<SvgIconTypeMap> | React.FC;
  EndIcon?: OverridableComponent<SvgIconTypeMap> | React.FC;
};

export type ToiLinkTypeMap = {
  props: OwnProps & LinkOwnProps;
  defaultComponent: 'a';
};

export type ToiLinkProps<C extends ElementType = 'a'> = LinkProps<C, OwnProps>;

const StyledLink = styled(Link, {
  //we need these so that  mui will not forward these to the DOM and giv us warnings
  shouldForwardProp: (prop) => !['variant', 'uppercase', 'color', 'definition', 'inline'].includes(prop.toString()),
})<ToiLinkProps>(({theme, variant = getDefaultTypographyVariant(theme), uppercase, color, definition, inline}) => {
  validateTypographyVariant(variant);
  validateTypographyAttributes({variant, uppercase});

  return toiLinkStyle(theme, variant, uppercase, color, definition, inline);
}) as typeof Link;

export const ToiLink = React.forwardRef<HTMLAnchorElement, ToiLinkProps>((props, ref) => {
  const {StartIcon, EndIcon, ...restProps} = props;

  return (
    <StyledLink
      {...restProps}
      href={'href' in restProps && typeof restProps.href === 'string' ? getSecureUrl(restProps.href) : undefined}
      ref={ref}
    >
      {StartIcon && <StartIcon />}
      {props.children}
      {EndIcon && <EndIcon />}
    </StyledLink>
  );
}) as OverridableComponent<ToiLinkTypeMap>;

export default ToiLink;
