import {useMutation, useQuery, useQueryClient} from 'react-query';
import {deletePost, fetchPosts, postPost} from '../Requests/PostRequests';
import PostType from '../Types/PostType';

export function usePosts(clientId: string, applicationProfile: string | undefined, authToken: string | undefined) {
  const queryClient = useQueryClient();

  const posts = useQuery<PostType[], Error>(['posts', clientId], () => fetchPosts(clientId));

  const createOrUpdatePost = useMutation<unknown, Error, PostType, unknown>(
    (post) => postPost(clientId, post, authToken, applicationProfile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['posts', clientId]);
      },
    },
  );

  const removePost = useMutation<unknown, Error, PostType, unknown>(
    (post) => deletePost(clientId, post, authToken, applicationProfile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['posts', clientId]);
      },
    },
  );

  return {
    posts: {...posts, data: posts.data ?? []},
    createOrUpdatePost,
    removePost,
  };
}
