import {Theme} from '@mui/material';
import {merge} from 'lodash-es';
import {ToiLinkVariant} from '../ToiLink/ToiLink';
import {ToiTypographyVariant, TypographyAttributes} from './ToiTypography';

export const h1 = (atts: TypographyAttributes, mobile?: boolean) => {
  return {
    fontWeight: atts.uppercase ? 700 : 500,
    fontSize: atts.uppercase ? (mobile ? '30px' : '36px') : mobile ? '34px' : '48px',
    lineHeight: '110%',
    textTransform: atts.uppercase ? 'uppercase' : 'none',
  };
};

export const h2 = (atts: TypographyAttributes, mobile?: boolean) => {
  return {
    fontWeight: 500,
    fontSize: mobile ? '26px' : '32px',
    lineHeight: '100%',
  };
};
export const h3 = (atts: TypographyAttributes) => {
  return {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '100%',
  };
};
export const h4 = (atts: TypographyAttributes) => {
  return {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '100%',
  };
};
export const subtitle1 = (atts: TypographyAttributes) => {
  return {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '120%',
    textTransform: 'uppercase',
  };
};
export const subtitle2 = (atts: TypographyAttributes) => {
  return {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '120%',
  };
};
export const body1 = (atts: TypographyAttributes, mobile?: boolean) => {
  return {
    fontWeight: atts?.light ? 300 : 400,
    fontSize: '20px',
    lineHeight: '130%',
    fontStyle: atts?.italic ? 'italic' : 'normal',
  };
};
export const body2 = (atts: TypographyAttributes) => {
  return {
    fontWeight: atts?.light ? 300 : atts?.bold ? 600 : 400,
    fontSize: '16px',
    lineHeight: '140%',
    fontStyle: atts?.italic ? 'italic' : 'normal',
  };
};
export const body3 = (atts: TypographyAttributes) => {
  return {
    fontWeight: atts?.light ? 300 : atts?.bold ? 600 : 400,
    fontSize: '14px',
    lineHeight: '140%',
    fontStyle: atts?.italic ? 'italic' : 'normal',
  };
};
export const body4 = (atts: TypographyAttributes) => {
  return {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '100%',
  };
};
export const label = (atts: TypographyAttributes) => {
  return {
    fontWeight: atts.semibold ? 600 : atts?.light ? 400 : 700,
    fontSize: '14px',
    lineHeight: '24px',
    textTransform: atts.semibold ? 'none' : 'uppercase',
  };
};

export const getVariantStyle = (
  variant: ToiTypographyVariant | ToiLinkVariant,
  atts: TypographyAttributes,
  mobile?: boolean,
) => {
  switch (variant) {
    case 'h1':
      return h1(atts, mobile);
    case 'h2':
      return h2(atts, mobile);
    case 'h3':
      return h3(atts);
    case 'h4':
      return h4(atts);
    case 'subtitle1':
      return subtitle1(atts);
    case 'subtitle2':
      return subtitle2(atts);
    case 'body1':
      return body1(atts, mobile);
    case 'body2':
      return body2(atts);
    case 'body3':
      return body3(atts);
    case 'body4':
      return body4(atts);
    case 'label':
      return label(atts);
    default:
      return body2(atts);
  }
};

export const toiTypographyStyle = (
  theme: Theme,
  variant: ToiTypographyVariant,
  atts: TypographyAttributes,
  mobile: boolean,
) => {
  const defaultStyle = {fontFamily: 'figtree', maxWidth: '75ch', '& > b': {fontWeight: 600}};

  return merge({}, defaultStyle, getVariantStyle(variant, atts, mobile));
};
