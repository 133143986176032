import * as React from 'react';
import {Button, Grid, Typography, CircularProgress} from '@mui/material/';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import * as parser from 'cron-parser';
import moment from 'moment-timezone';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import useKonsistenssjekk from '../../../../hooks/konsistenssjekk/useKonsistenssjekk';
import {LogLines} from '../../../../hooks/konsistenssjekk/types';
import useCan from '../../../../utils/auth/useCan';
import {setTitle} from '../../../../utils/setTitle';
import useMeta from '../../../../hooks/meta/useMeta';
import useRunKonsistenssjekk from '../../../../hooks/konsistenssjekk/useRunKonsistenssjekk';
import KonsistenssjekkIsRunning from './KonsistenssjekkIsRunning';
import KonsistenssjekkTable from './KonsistenssjekkTable';

const newDateFormat = (dateObject: Date | string) => {
    return new Date(dateObject).toLocaleDateString('nb-NO', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

//Used for calculating how long the last report took to run
const timeDiffMinutes = (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const numbers = new Date(endDate.getTime() - startDate.getTime());
    if (isNaN(numbers.getSeconds())) return false;
    const result =
        numbers.getMinutes() +
        ':' +
        (numbers.getSeconds() < 10
            ? '0' + numbers.getSeconds()
            : numbers.getSeconds());

    return result !== '0:00' ? result : false;
};

const parseCronExpression = (cronString: string) => {
    if (cronString === '') return;
    const whitespaceCount = cronString.trim().split(' ').length - 1;

    let formattedCronExpression = cronString;
    if (whitespaceCount > 5) {
        formattedCronExpression = cronString.substring(
            0,
            cronString.lastIndexOf(' ')
        );
    } else if (whitespaceCount < 5) {
        formattedCronExpression = '0 ' + cronString;
    }

    try {
        const konsistenssjekkScheduledCronParse = parser
            .parseExpression(formattedCronExpression)
            .next()
            .toDate();
        const formatDateTime = newDateFormat(konsistenssjekkScheduledCronParse);

        return formatDateTime;
    } catch {
        return;
    }
};

export default function Konsistenssjekk() {
    setTitle('Rapporter > Konsistenssjekk');
    const [infiniteScrollData, setInfiniteScrollData] =
        React.useState<LogLines[]>();
    const [infiniteScrollCount, setInfiniteScrollCount] = React.useState(0);
    const can = useCan();
    const meta = useMeta();
    const konsistenssjekk = useKonsistenssjekk();
    const runKonsistenssjekk = useRunKonsistenssjekk();
    const [searchText, setSearchText] = React.useState('');
    const [showRunningReport, setShowRunningReport] = React.useState(false);
    const [isRunClicked, setIsRunClicked] = React.useState(false);
    const reportJobFailedMessage = konsistenssjekk.reportFailed;
    const nextScheduledKonsistenssjekk = parseCronExpression(
        meta.konsistenssjekkScheduler
    );
    const lastRunTime = timeDiffMinutes(
        konsistenssjekk.data?.startDato ? konsistenssjekk.data?.startDato : '',
        konsistenssjekk.data?.dato ? konsistenssjekk.data?.dato : ''
    );

    //Resetting scroll count for infinite scroll (0), and clears search if report view is changed (temp and current report)
    React.useEffect(() => {
        setInfiniteScrollCount(0);
        setSearchText('');
        showRunningReport
            ? setInfiniteScrollData(konsistenssjekk.tempLoglines)
            : handleInfiniteScroll(konsistenssjekk.loglines);
    }, [showRunningReport]);

    React.useEffect(() => {
        if (
            konsistenssjekk.tempLoglines &&
            konsistenssjekk.isRunning &&
            showRunningReport
        )
            setInfiniteScrollData(konsistenssjekk.tempLoglines);
    }, [konsistenssjekk.tempLoglines]);

    //If row count for infifinitescroll is set to 0 and search is inactive, run function to load 50 first rows (happens every time someone switches between the tempreport and report views)
    React.useEffect(() => {
        if (infiniteScrollCount === 0) {
            !showRunningReport &&
                handleInfiniteScroll(konsistenssjekk.loglines);
        }
    }, [infiniteScrollCount]);

    const handleInfiniteScroll = (displayedData: LogLines[] | undefined) => {
        if (!showRunningReport) {
            const newInfiniteScrollData = displayedData?.slice(
                0,
                infiniteScrollCount + 50
            );
            setInfiniteScrollCount(infiniteScrollCount + 50);
            setInfiniteScrollData(newInfiniteScrollData);
        }
    };
    React.useEffect(() => {
        if (infiniteScrollCount === 0 && !infiniteScrollData)
            handleInfiniteScroll(konsistenssjekk.loglines);
    }, [infiniteScrollCount, infiniteScrollData]);

    //Initiates a konsistenssjekk
    const handleRunKonsistenssjekk = async () => {
        setIsRunClicked(true);
        await runKonsistenssjekk.mutateAsync();
        konsistenssjekk.refetch();
    };

    //Keeps track of running report status
    React.useEffect(() => {
        if (konsistenssjekk.isRunning) {
            setIsRunClicked(false);
        } else {
            setShowRunningReport(false);
            setInfiniteScrollCount(0);
            setInfiniteScrollData(undefined);
        }
    }, [konsistenssjekk.isRunning]);

    if (konsistenssjekk.isLoading)
        return <LoadingSpinner circularProgressProps={{color: 'secondary'}} />;
    if (can.edit && meta.hasFeature('konsistenssjekk_tilgang'))
        return (
            <div style={{height: 'fit-content'}}>
                <Grid container={true}>
                    <Grid item={true}>
                        <Typography
                            variant='subtitle1'
                            style={{paddingBottom: 50}}
                        >
                            Viser ulikheter mellom delte egenskaper som ligger
                            lagret i både arealplaner.no og QMS.
                        </Typography>
                        {!konsistenssjekk.isRunning ? (
                            meta.hasFeature(
                                'konsistenssjekk_manuellkjoring'
                            ) && (
                                <div>
                                    <Button
                                        sx={{
                                            marginBottom: 10,
                                        }}
                                        variant='outlined'
                                        color='secondary'
                                        disabled={isRunClicked}
                                        onClick={handleRunKonsistenssjekk}
                                    >
                                        {!isRunClicked ? (
                                            <PlayArrowIcon fontSize='large' />
                                        ) : (
                                            <CircularProgress
                                                style={{marginRight: 5}}
                                                color='secondary'
                                                size={24}
                                            />
                                        )}
                                        Kjør konsistenssjekk
                                    </Button>
                                    {reportJobFailedMessage && (
                                        <Typography color='error'>
                                            Forrige kjøring feilet:{' '}
                                            {reportJobFailedMessage}
                                        </Typography>
                                    )}
                                </div>
                            )
                        ) : (
                            <KonsistenssjekkIsRunning
                                currentPage={
                                    konsistenssjekk.data.tempCurrentPage
                                }
                                totalPages={konsistenssjekk.data.tempNumPages}
                                setShowRunningReport={setShowRunningReport}
                                showRunningReport={showRunningReport}
                            />
                        )}
                        <Grid
                            item={true}
                            xs={12}
                            style={{
                                marginBottom: 10,
                            }}
                        >
                            <Typography style={{paddingTop: 20}}>
                                Siste kjørt:{' '}
                                {konsistenssjekk.data?.dato
                                    ? newDateFormat(
                                          moment
                                              .utc(konsistenssjekk.data.dato)
                                              .tz(
                                                  Intl.DateTimeFormat().resolvedOptions()
                                                      .timeZone
                                              )
                                              .utc()
                                              .format()
                                      )
                                    : 'Aldri kjørt'}
                            </Typography>
                            <Typography>
                                Kjøretid forrige kjøring:{' '}
                                {lastRunTime ? lastRunTime : 'Aldri kjørt'}
                            </Typography>
                            <Typography style={{paddingBottom: 20}}>
                                Neste planlagte kjøring:{' '}
                                {nextScheduledKonsistenssjekk
                                    ? nextScheduledKonsistenssjekk
                                    : 'Ingen planlagt kjøring'}
                            </Typography>
                            {showRunningReport && (
                                <Typography variant='subtitle2'>
                                    Viser feil avdekket så langt i kjøringen
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <React.Suspense
                    fallback={
                        <LoadingSpinner
                            circularProgressProps={{color: 'secondary'}}
                        />
                    }
                >
                    <KonsistenssjekkTable
                        key={'konsistenssjekk-rapport'}
                        handleInfiniteScroll={handleInfiniteScroll}
                        infiniteScrollData={infiniteScrollData}
                        konsistenssjekk={konsistenssjekk}
                        kundeId={meta.kundeId}
                        kommuneNummer={meta.komnr}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        showRunningReport={showRunningReport}
                    />
                </React.Suspense>
            </div>
        );

    return (
        <Typography>Du har ikke rettigheter til å se denne siden.</Typography>
    );
}
