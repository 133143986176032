//ts-ignore
import mapboxgl, {GeoJSONSourceRaw, Map, Marker, RasterSource} from 'mapbox-gl';
import {Coords, MapboxLayer, marker, Popup, WmsLayer} from './types';

export const drawWmsLayers = (
  wmsLayers: WmsLayer[] | undefined,
  activeWmsLayers: WmsLayer[],
  setActiveWmsLayers: (layers: WmsLayer[]) => void,
  map: Map,
  layers: MapboxLayer[] | undefined,
) => {
  const newLayers = wmsLayers ? wmsLayers.filter((layer) => !existInList(layer, activeWmsLayers)) : [];
  const removedLayers = wmsLayers ? activeWmsLayers.filter((layer) => !existInList(layer, wmsLayers)) : [];
  if (newLayers.length === 0 && removedLayers.length === 0) return;

  removedLayers.forEach((layer) => {
    removeLayerFromMap(layer, map);
  });
  newLayers.forEach((wmsLayer) => {
    addWmsLayerToMap(wmsLayer, map);
  });

  setActiveWmsLayers(wmsLayers || []);

  //Move existing layers to front if wms was updated
  if (layers) {
    moveLayersToFront(layers, map);
  }
};

const existInList = (object: any, list: any[]): boolean => {
  let exist = false;
  list.forEach((item) => {
    if (isEqual(item, object)) exist = true;
  });
  return exist;
};

const isEqual = (object1: any, object2: any): boolean => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const drawMarker = (
  marker: marker | Coords,
  activeMarker: any,
  setActiveMarker: (marker: Marker | undefined) => void,
  map: any,
) => {
  let formattedMarker: marker = marker as marker;

  if (!formattedMarker.coords) {
    formattedMarker = {coords: marker as Coords};
  }
  if (activeMarker) {
    activeMarker.remove();
    setActiveMarker(undefined);
  }
  if (formattedMarker.coords) {
    // add new marker if set
    const el = document.createElement('div');
    el.className = 'marker';
    activeMarker = new mapboxgl.Marker(el, {
      offset: [0, -25],
      draggable: formattedMarker.draggable,
    })
      .setLngLat([formattedMarker.coords.lng, formattedMarker.coords.lat])
      .addTo(map);
    if (formattedMarker.draggable) {
      activeMarker.on('dragend', (res: any) => {
        formattedMarker.onDragEnd && formattedMarker.onDragEnd(res.target._lngLat);
      });
    }
    setActiveMarker(activeMarker);
  }
};

export const addPopup = (popup: Popup, map: Map) => {
  if (popup.html) {
    const mapPopup = new mapboxgl.Popup().setLngLat(popup.point).setDOMContent(popup.html).addTo(map);
  } else if (popup.htmlString) {
    const mapPopup = new mapboxgl.Popup().setLngLat(popup.point).setHTML(popup.htmlString).addTo(map);
  }
};

export const drawLayers = (
  layers: MapboxLayer[] | undefined,
  activeLayers: MapboxLayer[],
  setActiveLayers: (layers: MapboxLayer[]) => void,
  map: Map,
) => {
  const newLayers = layers ? layers.filter((layer) => !existInList(layer, activeLayers)) : [];
  const removedLayers = layers ? activeLayers.filter((layer) => !existInList(layer, layers)) : [];

  if (newLayers.length === 0 && removedLayers.length === 0) return;

  removedLayers.forEach((layer) => {
    removeLayerFromMap(layer, map);
  });

  newLayers.forEach((layer) => {
    drawLayer(layer, map);
  });
  setActiveLayers(layers || []);
};

const removeLayerFromMap = (layer: WmsLayer | MapboxLayer, map: Map) => {
  if (map.getSource(layer.id) && map.getLayer(layer.id)) {
    map.removeLayer(layer.id);
    map.removeSource(layer.id);
  } else {
    console.error('trying to remove source that doesnt exist');
  }
};

const addWmsLayerToMap = (wmsLayer: WmsLayer, map: Map) => {
  const source = map.getSource(wmsLayer.id) as RasterSource;
  if (source) {
    console.error('layer already exist!');
  }
  const beforeLayer = wmsLayer.beforeLayerId || undefined;
  map.addLayer(
    {
      id: wmsLayer.id,
      type: 'raster',
      source: {
        type: 'raster',
        tiles: [wmsLayer.url],
        tileSize: wmsLayer.tileSize || 256,
      },
      minzoom: wmsLayer.minZoom,
      maxzoom: wmsLayer.maxZoom,
      paint: wmsLayer.paint || {},
    },
    beforeLayer,
  );
};

const moveLayersToFront = (layers: MapboxLayer[], map: Map) => {
  if (layers) {
    layers.forEach((layer) => {
      if (map.getLayer(layer.id)) {
        map.moveLayer(layer.id, layer.properties && layer.properties.beforeLayerId);
      }
    });
  }
};

const drawLayer = (layer: MapboxLayer, map: Map) => {
  const existingSource = map.getSource(layer.id) as mapboxgl.GeoJSONSource;
  if (existingSource) {
    const newSource = layer.source as GeoJSONSourceRaw;
    const newData: any = newSource.data;
    if (newData) existingSource.setData(newData);
    return;
  }
  if (layer.properties && layer.properties.beforeLayerId) {
    if (map.getLayer(layer.properties.beforeLayerId)) {
      map.addLayer(layer as any, layer.properties.beforeLayerId);
    } else {
      console.error('Layer with id: ' + layer.properties.beforeLayerId + ' does not exist on the map');
      map.addLayer(layer as any);
    }
  } else {
    map.addLayer(layer as any);
  }
};
