//THIS FILE IS AUTO-generated, DO NOT EDIT!
import React from 'react';
import {PathProps} from '../SvgIcon';
import createSvgIcon from '../createIcon';

export const AppEditIcon = createSvgIcon(
    (props: PathProps): React.ReactNode => (
        <g>
            <title>{props.title}</title>
            <desc>{props.desc}</desc>
            <path fillRule='evenodd' clipRule='evenodd' d='M19 20.03V13.03L21 11.03V22.03H2V3.03003H13L11 5.03003H4V7.03003V20.03H19ZM15 5.28003L18.75 9.03003L10.75 17.03H7V13.28L15 5.28003Z' style={props.secondaryStyle} />
            <path d='M22.03 5.75L18.28 2L16 4.28003L19.75 8.03003L22.03 5.75Z' style={props.primaryStyle} />
        </g>
    ),
    'AppEditIcon', // aria-label
);
